import React from 'react'
import SlideShow from '../../component/homepage/slieshow'
import HomePageFirstComponent from '../../component/homepage/homePageFirstComponent'
import HomePageSecondComponent from '../../component/homepage/homePageSecondComponent'
import HomePageThirdComponent from '../../component/homepage/homePageThirdComponent'
import HomePageFourthComponent from '../../component/homepage/homePageFourthComponent'

const HomePage = () => {
    return (
        <>
            <SlideShow />
            <HomePageFirstComponent />
            <HomePageSecondComponent />
            <HomePageThirdComponent />
            <HomePageFourthComponent />
        </>
    )
}

export default HomePage