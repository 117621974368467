import React from 'react'
import './styles.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import background from '../../../assets/images/background.png'
import background2 from '../../../assets/images/backgorund2.png'
import background3 from '../../../assets/images/background3.png'
import { Pagination, Autoplay } from 'swiper/modules';
import { Box, Button, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';

const SlideShow = () => {

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    };

    return (
        <>
            <Swiper
                loop={true}
                pagination={pagination}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
                className="SldieShowWrapper"
            >
                <SwiperSlide>
                    <Box className="SlideShowItemContainer">
                        <img src={background} className='SlideShowItemImage' alt='background' />
                        <Box className='SlideShowtemOverlay'>
                            <Typography variant='h1' className='SlideShowtemOverlayTitle'>
                                Acquire genuine users, not individuals<br /> engaging in fraudulent activities!
                            </Typography>
                            <Typography variant='span22' className='SlideShowtemOverlaySubTitle'>
                                Wave goodbye to new account fraud!
                            </Typography>
                            <Link to='/signin'>
                                <Button className='SlideShowtemOverlayButton1'>
                                    Disccover More <KeyboardArrowRightIcon color='#fff' />
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </SwiperSlide>
                <SwiperSlide>
                    <Box className="SlideShowItemContainer">
                        <img src={background2} className='SlideShowItemImage' alt='background2' />
                        <Box className='SlideShowtemOverlay'>
                            <Typography variant='h1' className='SlideShowtemOverlayTitle'>
                                Put an end to ATO and coupon fraud<br /> entirely within the gambling group.
                            </Typography>
                            <Typography variant='span22' className='SlideShowtemOverlaySubTitle'>
                                Explore our success in completely preventing<br /> Account Takeover and Coupon Fraud for a gambling group.
                            </Typography>
                            <Link to='/about-us'>
                                <Button className='SlideShowtemOverlayButton2'>
                                    About Us <KeyboardArrowRightIcon color='#fff' />
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </SwiperSlide>
                <SwiperSlide>
                    <Box className="SlideShowItemContainer">
                        <img src={background3} className='SlideShowItemImage' alt='background3' />
                        <Box className='SlideShowtemOverlay'>
                            <Typography variant='h1' className='SlideShowtemOverlayTitle'>
                                Secure your digital service by <br /> prioritizing your customer's safety.
                            </Typography>
                            <Typography variant='span22' className='SlideShowtemOverlaySubTitle'>
                                Our website safeguards digital services globally,<br /> shielding them from digital fraud and security threats.
                            </Typography>
                            <Link to='/system'>
                                <Button className='SlideShowtemOverlayButton3'>
                                    Discover our System <KeyboardArrowRightIcon color='#fff' />
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </SwiperSlide>

            </Swiper>
        </>
    )
}

export default SlideShow