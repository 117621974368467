import React from 'react'
import './styles.scss'
import { Box, Container, Typography } from '@mui/material'
import { aboutUs } from '../../../data'
import { arabToRoman } from 'roman-numbers'

const AboutUsSecondComponent = () => {
    return (
        <Container sx={{ padding: '32px 0px' }}>
            <Box className="AboutUsSecondComponentTitlesContainer">
                <Typography variant='h2' className='AboutUsSecondComponentTitle'>
                    What sets us apart?
                </Typography>
                <Typography variant='span18' className='AboutUsSecondComponentSubTitle'>
                    Aligning with your digital venture, we collaboratively strive to cultivate a secure and conducive environment for growth.
                    Entrusting us equates to placing your digital users in reliable hands.
                </Typography>
            </Box>
            <Box className='AboutUsSecondComponentCardsContainer'>
                {
                    aboutUs?.map((about, index) => {
                        return (
                            <Box className='AboutUsSecondComponentCard'>
                                <Box className="AboutUsSecondComponentCardNumber">
                                    <Typography variant='h3' className="AboutUsSecondComponentCardNumberText">
                                        {arabToRoman(index + 1)}.
                                    </Typography>
                                    <Typography variant="span18" className='AboutUsSecondComponentCardTitle'>
                                        {about?.title}
                                    </Typography>
                                </Box>
                                <Box sx={{ marginTop: "8px" }}>
                                    <Typography variant='spanM' className='AboutUsSecondComponentCardSubTitle'>
                                        {about?.subTitle}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        </Container>
    )
}

export default AboutUsSecondComponent