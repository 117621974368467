import icon1 from './assets/images/icons/icon1.png'
import icon2 from './assets/images/icons/icon2.png'
import icon3 from './assets/images/icons/icon3.png'
import icon4 from './assets/images/icons/icon4.png'
import icon5 from './assets/images/icons/icon5.png'
import icon6 from './assets/images/icons/icon6.png'
import icon7 from './assets/images/icons/icon7.png'
import icon8 from './assets/images/icons/icon8.png'
import icon9 from './assets/images/icons/icon9.png'
import icon10 from './assets/images/icons/icon10.png'
import internet from './assets/images/icons/internet.jpg'
import fintech from './assets/images/icons/fintech.png'
import car from './assets/images/icons/car.png'
import bet from './assets/images/icons/bet.png'
import system1 from './assets/images/icons/system1.png'
import system2 from './assets/images/icons/system2.png'
import system3 from './assets/images/icons/system3.png'
import system4 from './assets/images/icons/system4.png'
import system5 from './assets/images/icons/system5.png'
import system6 from './assets/images/icons/system6.png'

export const scenarios = [
    {
        name: 'Unauthorized Account Access Prevention',
        sub: 'Ensure the security of user accounts while ensuring a seamless experience.',
        icon: icon1,
        width: '70',
        height: '70'
    },
    {
        name: 'Anti-Registration Fraud Measures',
        sub: 'Prevent fraudulent activities during the account opening process.',
        icon: icon2,
        width: '70',
        height: '70'

    },
    {
        name: 'Transaction Security',
        sub: 'Effectively tackle payment fraud using a holistic, omnichannel protection strategy.',
        icon: icon3,
        width: '70',
        height: '60'
    },
    {
        name: 'Credit Card Fraud Prevention',
        sub: 'Equip your banks with real-time fraud protection and analyze user behavior to mitigate the risk of fraudulent transactions.',
        icon: icon4,
        width: '70',
        height: '60'
    },
    {
        name: 'Detection of Money Laundering Accounts',
        sub: 'Address the money mule threat promptly, reducing manual efforts for fraud analysts.',
        icon: icon5,
        width: '65',
        height: '60'
    },
    {
        name: 'Protection Against Push Payment Scams',
        sub: 'Counter fraud resulting from Social Engineering Scams through user behavior analysis and a comprehensive approach.',
        icon: icon6,
        width: '70',
        height: '70'
    },
    {
        name: 'Safeguarding Against Policy Fraud',
        sub: 'Enhance security by protecting against Policy Abuse using behavioral biometrics.',
        icon: icon7,
        width: '70',
        height: '70'
    },
    {
        name: 'Automated Attacks Prevention',
        sub: 'Safeguard your business against Bot Attacks using XTNs robust Behavioral Analysis technology.',
        icon: icon8,
        width: '70',
        height: '70'
    },
    {
        name: 'Malicious Software Safeguard',
        sub: 'Secure your sensitive digital services, preventing them from becoming targets of malware campaigns.',
        icon: icon9,
        width: '70',
        height: '70'
    },
    {
        name: 'Electronic Commerce Security Measures',
        sub: 'Detect fraudulent activity by monitoring your customers digital profiles.',
        icon: icon10,
        width: '70',
        height: '70'
    },
]

export const aboutUs = [
    {
        title: "A Trusted Partnership",
        subTitle:
            "Collaborate with us in a shared mission to cultivate a secure digital ecosystem for your business growth. Putting your trust in us means placing the same trust in safeguarding your digital users.",
    },
    {
        title: "Outcome-Driven Success",
        subTitle:
            "Our success is intertwined with yours. Our solutions empower you to effectively shield your business and end-users from potential fraud attempts, ensuring successful outcomes.",
    },
    {
        title: "Operational Efficiency",
        subTitle:
            "Efficiently streamline your business operations by leveraging our solution. Reduce integration costs, meet compliance standards, automate processes, and liberate resources for more strategic initiatives.",
    },
    {
        title: "Safeguard Brand Reputation",
        subTitle:
            "We act as a protective shield for your brand, preventing potential reputational damage post cyber attacks and fraud incidents.",
    },
    {
        title: "Modular Flexibility",
        subTitle:
            "Experience the flexibility of our Cognitive Security Platform® – a single product that brings together features typically found in vertical solutions. Activate functionalities as needed, providing a modular approach to your cybersecurity needs.",
    },
    {
        title: "Unmatched Expertise",
        subTitle:
            "Rely on our dedicated team of highly qualified anti-fraud experts with decades of industry experience. Their in-depth understanding of cybersecurity challenges ensures tailored solutions for your unique problems.",
    },
    {
        title: "Proprietary Cognitive Security",
        subTitle:
            "Our products encapsulate our collective cybersecurity experience, translating human expertise into artificial intelligence. We independently develop solutions, ensuring cutting-edge protection through proprietary cognitive security.",
    },
    {
        title: "Omnichannel Protection",
        subTitle:
            "Effortlessly manage fraud across various banking channels using our unified console. Say goodbye to separate and vertical management, embracing omnichannel protection with ease.",
    },
];

export const aboutUsServices = [
    {
        title: 'Monetary Services',
        icon: internet,
        width: 300,
        height: 270,
        desc: "Financial institutions represent prime targets for cybercriminals, who seek valuable assets such as end-user credentials, personal information, and business account data to execute successful fraud schemes. In response to the industry shift towards mobile services, banks are actively navigating the delicate balance between enhancing security measures and optimizing user experience, prioritizing innovative functionalities. In the current landscape, the financial services sector requires an advanced and robust solution to proactively identify and thwart fraudulent activities and potential threats. The Cognitive Security Platform serves as a comprehensive defense mechanism, safeguarding your digital services and ensuring a swift return on investment coupled with heightened operational efficiency."
    },
    {
        title: 'Digital Finance',
        icon: fintech,
        width: 250,
        height: 250,
        desc: 'The dynamic growth of the Fintech sector has catalyzed a transformation in the spectrum of financial services available to both consumers and businesses (B2B). Fintech companies, while striving to shield their services from fraudulent activities and ensure compliance, also face the challenge of maintaining a steadfast focus on their core business objectives. Our platform serves as an ideal partner for Fintechs, offering a myriad of features that enable effective management. With agile integration and deployment capabilities, especially through Software as a Service (SaaS), our website stands out as a versatile and reliable solution.'
    },
    {
        title: 'Car Industry',
        icon: car,
        width: 250,
        height: 120,
        desc: 'In the fast-paced evolution of the automotive industry, there is a rapid shift towards embracing vehicle-to-vehicle and vehicle-to-infrastructure integrations. This strategic move aims to offer enhanced services to customers. Notably, numerous automotive vendors are actively distributing applications to customers, featuring functionalities such as unlocking vehicle doors, GPS localization access, consumption and usage monitoring, and limited diagnostic capabilities. However, this surge in app-based capabilities also raises the risk of potential exploitation by fraudsters seeking unauthorized access to users vehicles and sensitive information.Recognizing the critical need to prevent brand damage and foster trust, our website emphasizes a pivotal goal.'
    },
    {
        title: 'Betting',
        icon: bet,
        width: 250,
        height: 250,
        desc: 'Fraud has transcended beyond being solely a financial concern and has become a pervasive issue across various online services. Gaming and gambling platforms, in particular, are enticing targets for fraud, with Account Takeovers being a prevalent threat. In such instances, fraudsters focus on obtaining personal information, siphoning remaining funds, or exploiting unique loyalty benefits from victimsaccounts. Our website adopts a comprehensive approach to combat these challenges, leveraging advanced behavioral biometric techniques. This holistic strategy proves invaluable in identifying suspicious user activity. By employing an invisible evaluation flow, we can discreetly detect anomalies and, when necessary, escalate authentication challenges. '
    },
]

export const system = [
    {
        title: 'Users',
        icon: system1,
        content: 'Users engaging with online financial services, such as internet banking, seek to perform a variety of operations with ease and security across multiple devices. Whether they are transferring funds, managing accounts, or applying for services on PCs, smartphones, or tablets, their experience should be seamless and secure, free from technical hindrances or security concerns.',
    },
    {
        title: 'Indicators',
        icon: system2,
        content: 'Our platform employs a comprehensive array of indicators to sculpt a robust digital identity for each user. By integrating both technological metrics and nuanced behavioral patterns, we create a multidimensional profile that encapsulates the unique digital footprint of the user, enhancing the personalization and security of their online experience.',
    },
    {
        title: 'Tech',
        icon: system3,
        content: 'Our technological indicators serve as a barometer for the security health of the devices used to access our services. By meticulously scanning for signs of malware infections, configuration discrepancies, and evidence of application tampering, our platform ensures that each users device meets stringent security benchmarks, safeguarding against potential vulnerabilities.',
    },
    {
        title: 'Behavioral Biometrics',
        icon: system4,
        content: 'We delve into the intricacies of user-device interaction, analyzing behavioral biometrics with precision. Our system captures and interprets subtle nuances such as typing rhythms, mouse movement patterns, the angle and manner in which mobile devices are held, and the distinctive ways users walk with their devices. This data paints a vivid picture of user behavior, contributing to a more secure and personalized user authentication process.',
    },
    {
        title: 'Behavioral Analytics',
        icon: system5,
        content: 'Our behavioral analytics transcend traditional monitoring, offering a window into the users engagement with our digital services. From mapping the frequency and pattern of service usage to detecting irregularities in user behavior that might signal fraudulent intentions, our analytics platform is at the forefront of preemptively identifying and mitigating potential security risks.',
    },
    {
        title: 'Risk Score',
        icon: system6,
        content: 'The culmination of our analytical processes is the risk score—a dynamic metric that reflects the security standing of a users interaction with our platform at any given moment. This score informs our systems response, dictating the appropriate level of intervention, from minimal verification procedures for low - risk activities to more stringent measures for high - risk situations, ensuring that each action is as secure as it is user- friendly.',
    },
];

export const systemAccordion = [
    {
        id: 'panel1',
        title: 'Enhance Customer Security',
        content: 'Transition from simply knowing to actively securing your customers. Utilize our behavior-based algorithms to validate identities, securing your digital platform and its users.',
    },
    {
        id: 'panel2',
        title: 'Integrated Protection Layers',
        content: 'Deploy multifaceted anti-fraud measures across different aspects of user interaction, uniting an array of security features within a singular, cohesive product. Customize according to your needs, ensuring a scalable and code-free integration.',
    },
    {
        id: 'panel3',
        title: 'Streamlined System Integration',
        content: 'Our platform seamlessly incorporates with existing third-party or bespoke solutions, complementing and enhancing the security infrastructure already in place.',
    },
    {
        id: 'panel4',
        title: 'Cross-Channel Defense',
        content: 'Our holistic approach to fraud prevention covers all banking channels, centralizing fraud management to enhance efficiency and control across the board.',
    },
    {
        id: 'panel5',
        title: 'Optimized Fraud Prevention Workflows',
        content: 'Customize your anti-fraud response with our flexible platform, designed for high-efficiency management and rapid deployment, promising a better return on investment.',
    },
    {
        id: 'panel6',
        title: 'Seamless Security Experience',
        content: 'Our biometric analysis reduces user friction and elevates security, ensuring a seamless authentication process while upholding rigorous security standards.',
    },
];

