import React from 'react'
import PageIntroduciton from '../../component/common/introduction'
import ScenariosFirstComponent from '../../component/scenarios/ScenariosFirstComponent'
import AboutUsFourthComponent from '../../component/aboutus/aboutUsFourthComponent'

const Scenarios = () => {
    return (
        <>
            <PageIntroduciton title="Scenarios" description="" />
            <ScenariosFirstComponent />
            <AboutUsFourthComponent />
        </>
    )
}

export default Scenarios