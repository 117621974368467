import React from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import PublicLayout from './layout/publicLayout';
import HomePage from './pages/homepage';
import AboutUs from './pages/aboutus';
import Scenarios from './pages/scenarios';
import System from './pages/system';
import AuthLayout from './layout/AuthLayout';
import Signin from './pages/signin';
import ContactUs from './pages/contactUs';
import Tickets from './pages/tickets';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<PublicLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/scenarios" element={<Scenarios />} />
            <Route path="/system" element={<System />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/tickets" element={<Tickets />} />
          </Route>
          <Route path='/' element={<AuthLayout />}>
            <Route path="/signin" element={<Signin />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
