import React from 'react'
import './styles.scss'
import { Box, Button, Container, Typography } from '@mui/material'
import { scenarios } from '../../../data'
import TurnRightOutlinedIcon from '@mui/icons-material/TurnRightOutlined';
import { Link } from 'react-router-dom';

const HomePageFourthComponent = () => {
    return (
        <Box className="HomePageFourthComponentWrapper">
            <Box className="HomePageFourthComponentTitlesContainer">
                <Typography variant='h2' className='HomePageFourthComponentTitle'>
                    We acknowledge the daily challenges you're experiencing
                </Typography>
                <Typography variant='span22' className='HomePageFourthComponentSubTitle'>
                    Explore how our solutions can safeguard your online enterprise.
                </Typography>
            </Box>

            <Container>
                <Box className="HomePageFourthComponentCardsContainer">
                    {
                        scenarios?.slice(0, 5)?.map(scenario => {
                            return (
                                <Box className="HomePageFourthComponentCard">
                                    <Box className='HomePageFourthComponentCardIconContainer'>
                                        <img src={scenario?.icon} width={scenario?.width} height={scenario?.height} alt={scenario?.name} />
                                    </Box>
                                    <Box className="HomePageFourthComponentCardTitlesContainer">
                                        <Typography variant='span22' className='HomePageFourthComponentCardTitle'>
                                            {scenario?.name}
                                        </Typography>
                                        <br />
                                        <br />
                                        <Typography variant='span16' className='HomePageFourthComponentCardSub'>
                                            {scenario?.sub}
                                        </Typography>
                                    </Box>
                                    <Box className="HomePageFourthComponentButtonContainer">
                                        <Link to='/scenarios'>
                                            <Button className='HomePageFourthComponentButton'>
                                                View More <TurnRightOutlinedIcon />
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Container>
        </Box>
    )
}

export default HomePageFourthComponent