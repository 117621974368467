import { Box, Typography } from '@mui/material'
import React from 'react'
import pageBackground from '../../../assets/images/page-background.png'
import './styles.scss'

const PageIntroduciton = ({ title, description1, description2 }) => {
    return (
        <Box className="AboutUsFirstComponentContainer">
            <img src={pageBackground} className='AboutUsFirstComponentImage' alt='background3' />
            <Box className='AboutUsFirstComponentOverlay'>
                <Typography variant='h1' className='AboutUsFirstComponentOverlayTitle'>
                    {title}
                </Typography>
                <Typography variant='span22' className='AboutUsFirstComponentOverlaySubTitle'>
                    {description1}<br />{description2}
                </Typography>

            </Box>
        </Box>
    )
}

export default PageIntroduciton