import React, { useState } from 'react'
import './styles.scss'
import { Box, List, ListItemButton, ListItemText, Typography, Paper, Container, Grid } from '@mui/material'
import { scenarios } from '../../../data';
import Typewriter from 'typewriter-effect';

function TypewriterText({ text }) {
    return (
        <Typography variant="h4" component="h1" gutterBottom>
            <Typewriter
                options={{
                    strings: text,
                    autoStart: true,
                    loop: true,
                    delay: "80"
                }}
            />
        </Typography>
    );
}

// scenarios
const ScenariosFirstComponent = () => {
    const [selectedScenario, setSelectedScenario] = useState(scenarios[0]);

    const handleListItemClick = (scenario) => {
        setSelectedScenario(scenario);
    };
    return (
        <Container sx={{ my: 4 }} className='ScenariosFirstComponent'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Paper elevation={3} sx={{ boxShadow: "none" }}>
                        <List component="nav">
                            {scenarios?.map((scenario) => (
                                <ListItemButton
                                    key={scenario.name}
                                    selected={selectedScenario.name === scenario.name}
                                    onClick={() => handleListItemClick(scenario)}
                                    sx={{
                                        color: selectedScenario.name === scenario.name ? '#1367DF' : '#1367DF',
                                        border: selectedScenario.name === scenario.name ? '2px solid #1367DF' : '2px solid #fff',
                                        borderRadius: '100px',
                                        '&:hover': {
                                            bgcolor: '#00A3FB',
                                            color: '#fff',
                                            borderRadius: '100px'

                                        },
                                        '&.Mui-selected': {
                                            bgcolor: "transparent",
                                            '&:hover': {
                                                bgcolor: '#00A3FB',
                                                color: '#fff',
                                                borderRadius: '100px'
                                            },

                                        },
                                    }}
                                >
                                    <ListItemText primary={scenario.name} />
                                </ListItemButton>
                            ))}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8} className='ScenariosFirstComponentContentContainer'>
                    <Box className='ScenariosFirstComponentContent'>
                        <TypewriterText text={selectedScenario.name} />
                        <Typography variant="h6" component="h1" gutterBottom sx={{ textAlign: "center" }}>
                            {selectedScenario.sub}
                        </Typography>
                        <Box className='ScenariosFirstComponentContentImageContainer'>
                            <Box
                                className='ScenariosFirstComponentContentImage'
                                component="img"
                                sx={{
                                    height: 150,
                                    width: 160,
                                }}
                                alt={selectedScenario.name}
                                src={selectedScenario.icon}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ScenariosFirstComponent