import React, { useState } from 'react'
import './styles.scss'
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Typography } from '@mui/material'
import { systemAccordion } from '../../../data';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SystemThirdComponent = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <Box className='SystemThirdComponentWrapper'>
            <Box>
                <Typography variant='h4' className='SystemThirdComponentTitle'>
                    Benefits
                </Typography>
            </Box>
            <Container sx={{ marginTop: "24px" }}>
                <Grid container spacing={2}>
                    {systemAccordion.map((item) => (
                        <Grid item xxl={6} xl={6} lg={6}>
                            <Accordion
                                key={item.id}
                                expanded={expanded === item.id}
                                onChange={handleChange(item.id)}
                                className='SystemThirdComponentAccordion'
                                sx={{
                                    backgroundColor: expanded === item.id ? '#00A3FB' : 'transparent',
                                }}
                            >
                                <AccordionSummary
                                    className='SystemThirdComponentAccordionSummary'
                                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                                    aria-controls={`${item.id}-content`}
                                    id={`${item.id}-header`}
                                >
                                    <Typography fontWeight='bold' variant='subtitle'>{item.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        {item.content}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}

export default SystemThirdComponent