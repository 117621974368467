import React from 'react'
import './styles.scss'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Container, Grid } from '@mui/material';
import { aboutUsServices } from '../../../data';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const AboutUsThirdComponent = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box className="AboutUsThirdComponentWrapper">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                    <Tab label="Monetary Services" className='AboutUsThirdComponentTab' />
                    <Tab label="Digital Finance" className='AboutUsThirdComponentTab' />
                    <Tab label=" Car Industry" className='AboutUsThirdComponentTab' />
                    <Tab label="Betting" className='AboutUsThirdComponentTab' />
                </Tabs>
            </Box>
            <Container>
                {
                    aboutUsServices?.map((service, index) => {
                        return (
                            <CustomTabPanel value={value} index={index}>
                                <Grid container>
                                    <Grid xxl={7} xl={7} lg={7}>
                                        <Typography variant='h3' fontWeight='bold' marginBottom={4}>
                                            {service.title}
                                        </Typography>
                                        <Typography variant='span18'>
                                            {service.desc}
                                        </Typography>
                                    </Grid>
                                    <Grid xxl={5} xl={5} lg={5}>
                                        <Box className='AboutUsThirdComponentImageContainer'>
                                            <img src={service.icon} alt={service?.title} width={service?.width} height={service?.height} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CustomTabPanel>
                        )
                    })
                }

            </Container>
        </Box>
    );
}

export default AboutUsThirdComponent