import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import Slide from '@mui/material/Slide';
import './styles.scss';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import investguard from '../../../assets/images/investguard-logo.png';

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    HideOnScroll.propTypes = {
        children: PropTypes.element.isRequired,
        window: PropTypes.func,
    };

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const Navigation = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const cookie = new Cookies();
    // const navigate = useNavigate();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleLogout = () => {
        cookie.remove('token', { path: '/' });
        cookie.remove('firstname', { path: '/' });
        cookie.remove('lastname', { path: '/' });
        cookie.remove('username', { path: '/' });
        cookie.remove('id', { path: '/' });
        handleClose();
        // navigate('/');
    };

    const pages = [
        {
            name: 'Home',
            link: '/',
        },
        {
            name: 'About Us',
            link: '/about-us',
        },
        {
            name: 'Scenarios',
            link: '/scenarios',
        },
        {
            name: 'System',
            link: '/system',
        }
    ];

    const loggedInPages = [
        {
            name: 'Home',
            link: '/',
        },
        {
            name: 'About Us',
            link: '/about-us',
        },
        {
            name: 'Scenarios',
            link: '/scenarios',
        },
        {
            name: 'System',
            link: '/system',
        },
        {
            name: 'Contact Us',
            link: '/contact-us',
        },
        {
            name: 'Tickets',
            link: '/tickets',
        }
    ];

    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };


    const pageList = cookie.get('token') ? loggedInPages : pages

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar className='navbarWrapper'>
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Typography
                                variant="h6"
                                noWrap
                                component="a"
                                href="#app-bar-with-responsive-menu"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'none', md: 'flex' },
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    display: "flex",
                                    alignItems: 'center'
                                }}
                            >
                                <img src={investguard} width={40} height={45} alt='logo' />
                                <Typography ml={1} fontWeight='bold' variant='h6'>InvestGuard</Typography>
                            </Typography>

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                    {pageList.map((page) => (
                                        <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center" className='navbarText'>{page.name}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                            <Typography
                                variant="h5"
                                noWrap
                                component="a"
                                href="#app-bar-with-responsive-menu"
                                sx={{
                                    mr: 2,
                                    display: { xs: 'flex', md: 'none' },
                                    flexGrow: 1,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                LOGO
                            </Typography>
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, alignItems: "center", justifyContent: "center" }}>
                                {pageList.map((page) => (
                                    <NavLink
                                        to={page.link}
                                        key={page.name}
                                        style={{ textDecoration: "none" }}
                                        className={({ isActive }) =>
                                            isActive ? 'navbarButtonActive' : 'navbarButton'
                                        }
                                    >
                                        <Button
                                            onClick={handleCloseNavMenu}
                                            sx={{ display: 'block' }}
                                        >
                                            <Typography className='navbarText'>
                                                {page.name}
                                            </Typography>
                                        </Button>
                                    </NavLink>
                                ))}
                            </Box>
                            <Box>
                                {
                                    cookie.get('token') ?
                                        <>
                                            <IconButton
                                                // size="large"
                                                edge="end"
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                onClick={handleMenu}
                                                color="inherit"
                                            >
                                                <PersonIcon /><Typography variant='h6'>{cookie.get('username')}</Typography>
                                            </IconButton>
                                            <Menu
                                                id="menu-appbar"
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                // keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem onClick={handleLogout}>Log out</MenuItem>
                                            </Menu>
                                        </>
                                        :
                                        <Link to='/signin'>
                                            <Button className='loginButton'>
                                                <PersonIcon color='#fff' />Sign in
                                            </Button>
                                        </Link>
                                }
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </HideOnScroll>
            <Toolbar />
        </>
    )
}

export default Navigation