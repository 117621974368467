import React from 'react'
import { Outlet } from 'react-router-dom'
import Navigation from '../component/common/navigation'
import Footer from '../component/common/Footer'

function AuthLayout() {
    return (
        <div>
            <Outlet />
        </div>
    )
}

export default AuthLayout