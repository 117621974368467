import React, { useEffect, useRef, useState } from 'react'
import './styles.scss'
import { Box, Container, Typography } from '@mui/material'
import CountUp from 'react-countup';

const HomePageSecondComponent = () => {

    const [isVisible, setIsVisible] = useState(false);
    const countUpRef = useRef();

    const handleScroll = () => {
        const element = countUpRef.current;
        if (!isVisible && isElementInViewport(element)) {
            setIsVisible(true);
        }
    };

    const isElementInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    useEffect(() => {

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isVisible]);
    return (
        <Box className="HomePageSecondComponentWrapper">
            <Container className="HomePageSecondComponentContainer">
                <Box className="HomePageSecondComponentTitlesContainer">
                    <Typography variant='h2' className='HomePageSecondComponentTitle'>
                        Verified Benefit
                    </Typography>
                    <Typography variant='span22' className='HomePageSecondComponentSubTitle'>
                        Metrics Behind Our Cognitive Security Solution
                    </Typography>
                </Box>
                <Box className="HomePageSecondComponentContentContainer">
                    <Box className="HomePageSecondComponentBoxContainer">
                        <Typography variant='h2' className="HomePageSecondComponentBoxTitle">
                            <div ref={countUpRef}>
                                {isVisible && (
                                    <>
                                        +<CountUp start={0} end={100} duration={1} />K
                                    </>
                                )}
                            </div>
                        </Typography>
                        <Typography className="HomePageSecondComponentBoxDescription">
                            Tracked Incidents on a Monthly Basis
                        </Typography>
                    </Box>
                    <Box className="divider"></Box>
                    <Box className="HomePageSecondComponentBoxContainer">
                        <Typography variant='h2' className="HomePageSecondComponentBoxTitle">
                            <div ref={countUpRef}>
                                {isVisible && (
                                    <>
                                        +<CountUp start={0} end={15} duration={2} />K
                                    </>
                                )}
                            </div>
                        </Typography>
                        <Typography className="HomePageSecondComponentBoxDescription">
                            Tracked Incidents on a Monthly Basis
                        </Typography>
                    </Box>
                    <Box className="divider"></Box>
                    <Box className="HomePageSecondComponentBoxContainer">
                        <Typography variant='h2' className="HomePageSecondComponentBoxTitle">
                            <div ref={countUpRef}>
                                {isVisible && (
                                    <>
                                        +<CountUp end={99} duration={1} />% Optimality
                                    </>
                                )}
                            </div>

                        </Typography>
                        <Typography className="HomePageSecondComponentBoxDescription">
                            Tracked Incidents on a Monthly Basis
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default HomePageSecondComponent