import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import platform from '../../../assets/images/icons/platform.png';
import capabilities from '../../../assets/images/icons/capabilities.png';
import behavior from '../../../assets/images/icons/behavior.png';
import eye from '../../../assets/images/icons/eye.png';
import plus from '../../../assets/images/icons/plus.png';
import './styles.scss';

const HomePageThirdComponent = () => {
    return (
        <Box className="HomePageThirdComponentWrapper">
            <Container>
                <Grid container>
                    <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box className="HomePageThirdComponentImagesContainer">
                            <Box className="HomePageThirdComponentImages">
                                <img src={platform} width={120} height={120} />
                                <Typography variant='span22' className="HomePageThirdComponentImagesText">
                                    Single Platform
                                </Typography>
                            </Box>
                            <img src={plus} width={30} height={30} className='HomePageThirdComponentPlusImage' />
                            <Box className="HomePageThirdComponentImages">
                                <img src={capabilities} width={120} height={120} />
                                <Typography variant='span22' className="HomePageThirdComponentImagesText">
                                    Varied Capabilities
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                        <Box>
                            <Typography variant='h3' fontWeight='bold' marginBottom={1}>
                                Efficient Leadership
                            </Typography>
                            <Typography variant='span16'>
                                Our system provides a smooth anti-fraud management interface,
                                liberating your time to concentrate on your essential business functions.
                                It's a comprehensive, ready-made solution designed for the digital era,
                                ready to be implemented swiftly. Our modular strategy enables you to
                                effortlessly enable the functionalities you require, guaranteeing optimal
                                operational effectiveness and simplified administration.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} marginTop={7}>
                        <Box>
                            <Typography variant='h3' fontWeight='bold'>
                                Profitable Returns
                            </Typography>
                            <Typography variant='span16'>

                                Our offerings encapsulate our profound knowledge in cybersecurity and
                                fraud management. Through the amalgamation of our AI proprietary algorithms
                                with behavioral biometrics and analysis, we guarantee a seamless and secure end-user
                                experience. Furthermore, we provide all-encompassing protection for your various channels,
                                empowering you to enforce robust anti-fraud tactics.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} marginTop={7}>
                        <Box className="HomePageThirdComponentImagesContainer">
                            <Box className="HomePageThirdComponentImages">
                                <img src={behavior} width={100} height={100} />
                                <Typography variant='span22' className="HomePageThirdComponentImagesText">
                                    Interaction-based
                                </Typography>
                            </Box>
                            <img src={plus} width={30} height={30} className='HomePageThirdComponentPlusImage' />
                            <Box className="HomePageThirdComponentImages">
                                <img src={eye} width={120} height={120} />
                                <Typography variant='span22' className="HomePageThirdComponentImagesText">
                                    Seamless
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default HomePageThirdComponent