import React from 'react'
import './styles.scss'
import { Box, Grid, Typography, Paper } from '@mui/material';

const SystemFirstComponent = () => {
    return (
        <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3 }}>
            <Paper sx={{ margin: 'auto', overflow: 'hidden', my: 3, boxShadow: "none" }}>
                <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'center', my: 3 }}>
                    <Grid item xs={12} md={8} sx={{ p: 3 }}>
                        <Typography variant="h5" component="h2" gutterBottom className='systemFirstComponentContentTitle'>
                            Advanced Security Integration Platform
                        </Typography>
                        <Typography variant="body1" paragraph>
                            An integrated defense mechanism catering to online enterprises, aiming to reduce the risks in digital financial transactions and e-payments, ensuring seamless user interactions. This turnkey, ready-to-deploy solution ensures swift implementation.
                        </Typography>

                        <Typography variant="h6" component="h3" gutterBottom className='systemFirstComponentContentTitle'>
                            Distinctive Features of Our System
                        </Typography>
                        <Typography variant="body1" paragraph>
                            The flexibility of our platform allows for customized activation of vital features to enhance the security of your digital enterprise. It combines cutting-edge algorithms with behavioral biometrics and analysis, ensuring user convenience without compromising security. Implement versatile anti-fraud strategies across various channels through a unified interface that simplifies oversight and enhances control.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={4} sx={{ p: 3 }}>
                        <Typography variant="subtitle1" gutterBottom className='systemFirstComponentContentTitle'>
                            Unified Control Panel
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Merge individual product functions within one platform to ensure operational excellence and cost-effective technical management.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom className='systemFirstComponentContentTitle'>
                            Unnoticeable Integration
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Our system operates transparently, adapting to various customer processes while ensuring user-friendly experiences.
                        </Typography>

                        <Typography variant="subtitle1" gutterBottom className='systemFirstComponentContentTitle'>
                            Adaptive and Scalable
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Tailor your digital defense against fraud with a platform that adapts to your specific requirements, capable of evolving proactively to counter emerging security threats.
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}

export default SystemFirstComponent