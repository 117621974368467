import React from 'react'
import './styles.scss'
import { Box, Container, Typography, useMediaQuery } from '@mui/material'
import bye from '../../../assets/images/icons/bye.png'
import security from '../../../assets/images/icons/security.png'

const HomePageFirstComponent = () => {

    const mobile = useMediaQuery('(max-width:800px)')


    return (
        <>
            <Box className='HomePageFirstComponentWrapper'>
                <Container>
                    <Box className="HomePageFirstComponentTitlesContainer">
                        <Typography variant='h2' className='HomePageFirstComponentTitle'>
                            Place confidence in your digital user.
                        </Typography>
                        <br />
                        <Typography variant='span18' className='HomePageFirstComponentSubTitle'>
                            Underlining the significance of prioritizing the authenticity of users on
                            digital platforms to proactively mitigate fraudulent activities and security
                            threats, we have instituted a sophisticated system based on real-time behavioral
                            analysis. Our overarching objective is to establish and maintain a secure, trustworthy
                            online environment that instills confidence in users, ensuring their interactions unfold
                            with a sense of safety and reliability. Through this commitment, we aim to cultivate a
                            digital space where users can engage confidently, free from concerns about potential risks
                            or fraudulent behaviors.
                        </Typography>
                    </Box>
                    <Box className='HomePageFirstComponentDataContainer' >
                        <Box
                            className="HomePageFirstComponentDataBox"
                        // data-aos="fade-right"
                        // data-aos-anchor="#example-anchor"
                        // data-aos-offset="500"
                        // data-aos-duration="500"
                        >
                            <Box className="HomePageFirstComponentDataImage">
                                <img src={bye} width={100} height={100} />
                            </Box>
                            <Typography variant='h3' className="HomePageFirstComponentDataText">Eliminate Digital Fraud Risks</Typography>
                        </Box>
                        <Box
                            className="HomePageFirstComponentDataBox"
                        // data-aos="fade-left"
                        // data-aos-anchor="#example-anchor"
                        // data-aos-offset="500"
                        // data-aos-duration="500"
                        >
                            <Box className="HomePageFirstComponentDataImage2">
                                <img src={security} width={100} height={100} />
                            </Box>
                            <Typography variant='h3' className="HomePageFirstComponentDataText">Safeguard Your Reputation</Typography>
                        </Box>
                    </Box>

                </Container>
            </Box>
        </>
    )
}

export default HomePageFirstComponent