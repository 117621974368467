import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import './styles.scss';
import systemAnimation from '../../../assets/lottie/system.json';
import Lottie from 'react-lottie';
import { system } from '../../../data';

const SystemSecondComponent = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: systemAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Grid container className='SystemSecondComponentWrapper'>
            <Grid item xxl={5} xl={5} lg={5}>
                <Box className='SystemSecondComponentWrapperLottieContainer'>
                    <Lottie options={defaultOptions} height={500} width={500} />
                </Box>
            </Grid>
            <Grid item xxl={7} xl={7} lg={7}>
                <Box>
                    {system.map((section, index) => (
                        <Box key={index} mb={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ marginRight: '16px' }}>
                                <img src={section?.icon} alt={index} width={100} height={100} />
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h5" className='systemSecondComponentTitle'>{section.title}</Typography>
                                <Typography className='systemSecondComponentContent'>{section.content}</Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Grid>
        </Grid>
    )
}

export default SystemSecondComponent