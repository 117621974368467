import axios from "axios";

export const login = async (username, password) => {
    const response = await axios.post('/auth/users/login', {
        username,
        password
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}

export const createTicket = async (data) => {
    const response = await axios.post('/api/createTransaction', data);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}

export const SendContactInfo = async (
    generatedNumber,
    firstName,
    lastName,
    email,
    phoneNumber,
    amountLost,
    dateLost,
    message
) => {
    const response = await axios.post('/api/contactus', {
        generatedNumber,
        firstName,
        lastName,
        email,
        phoneNumber,
        amountLost,
        dateLost,
        message
    });
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data;
    }
}

export const getTickets = async (id) => {
    const response = await axios.get('/api/transactions/' + id);
    if (response.status === 200) { // response - object, eg { status: 200, message: 'OK' }
        return response.data
    }
}