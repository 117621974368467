import React, { useState } from 'react';
import { Card, CardContent, TextField, Button, Box, Typography, FormControl } from '@mui/material';
import { Form, Link, useNavigate } from 'react-router-dom';
import './styles.scss'
import Cookies from 'universal-cookie';
import { useFormik } from 'formik';
import { login } from '../../api';

const Signin = () => {
    const [message, setMessage] = useState()
    const cookie = new Cookies()
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: () => {
            const username = formik.values.username
            const password = formik.values.password;
            console.log(username, password)
            const func = login(username, password)
            if (username === "" && password === "") {
                navigate('/signin');
            } else {
                func.then(async (response) => {
                    if (response.success) {
                        cookie.set('token', response.token, { path: '/' })
                        cookie.set('firstname', response.data.firstName, { path: '/' })
                        cookie.set('lastname', response.data.lastName, { path: '/' })
                        cookie.set('username', response.data.username, { path: '/' })
                        cookie.set('id', response.data._id, { path: '/' })
                        navigate('/');
                    } else {
                        setMessage('User not found')
                    }
                })
            }

        }
    })
    return (
        <Box className='signinWrapper'>
            <Card className='signinContainer'>
                <CardContent>
                    <Typography variant="h5" component="div" gutterBottom sx={{ color: '#fff', textAlign: 'center', mb: 3 }}>
                        LOGO
                    </Typography>
                    <Typography className='signInMessage'>
                        {message}
                    </Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoComplete="username"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.username || ''}
                            autoFocus
                            variant="outlined"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '16px',
                                }
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password || ''}
                            autoComplete="current-password"
                            variant="outlined"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '16px'
                                },
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className='signinSubmitButton'
                        >
                            Log In
                        </Button>
                    </form>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button variant="text" component={Link} to="/" className='signinBackToHome'>
                            Back to Website
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

export default Signin