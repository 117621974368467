import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  typography: {
    h1: {
      fontSize: 48,
    },
    h2: {
      fontSize: 40,
    },
    h3: {
      fontSize: 30,
    },
    span22: {
      fontSize: 22
    },
    span18: {
      fontSize: 18
    },
    span16: {
      fontSize: 16
    },
    spanM: {
      fontSize: 14
    },
    span12: {
      fontSize: 12
    },
    span11: {
      fontSize: 11
    },
    span10: {
      fontSize: 10
    }
  },
});

theme = responsiveFontSizes(theme);


const root = document.getElementById('root');
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>,
  root
);


