import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Navigation from '../component/common/navigation'
import Footer from '../component/common/Footer'
import Cookies from 'universal-cookie';

function PublicLayout() {
    const cookie = new Cookies();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!cookie.get('token') && location.pathname === '/contact-us') {
            navigate('/')
        }
    }, [cookie, location])

    return (
        <div>
            <Navigation />
            <Outlet />
            <Footer />
        </div>
    )
}

export default PublicLayout